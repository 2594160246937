import { useFrame, useThree } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import { Vector3 } from 'three';

import useStore from '../../../store';

const useCodes = () => {
  const codes = useRef(new Set());
  const possibleCodes = [
    'KeyW',
    'KeyS',
    'KeyA',
    'KeyD',
    'Comma',
    'Period',
    'BracketRight',
    'Slash'
  ];

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (!possibleCodes.includes(e.code)) return;
      e.preventDefault();
      codes.current.add(e.code);
    };

    const onKeyUp = (e: KeyboardEvent) => {
      if (!possibleCodes.includes(e.code)) return;
      e.preventDefault();
      codes.current.delete(e.code);
    };

    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
    };
  }, []);
  return codes;
};

const vec = new Vector3();

export default function useKeyboardControls() {
  const { camera } = useThree();
  const controls = useStore(state => state.explore.controls);
  const minDistance = useStore(state => state.explore.config.minDistance);
  const maxDistance = useStore(state => state.explore.config.maxDistance);

  const code = useCodes();
  const speed = 100;

  const zoom = (z: number) => {
    if (!controls) return;
    const { x, y } = camera.position;

    let newZ = z;
    if (z < minDistance) newZ = minDistance;
    if (z > maxDistance) newZ = maxDistance;

    camera.position.set(x, y, newZ);
    controls.target.set(x, y, 0);
  };

  const move = (distance: number, axis: number = 0) => {
    if (!controls) return;
    if (camera.position.z > maxDistance - 0.2) return;

    vec.setFromMatrixColumn(camera.matrix, axis);
    camera.position.addScaledVector(vec, distance);
    controls.target.addScaledVector(vec, distance);
  };

  useFrame((_, delta) => {
    if (code.current.has('Period') || code.current.has('BracketRight')) zoom(delta * speed);
    if (code.current.has('Comma') || code.current.has('Slash')) zoom(-delta * speed);
    if (code.current.has('KeyW')) move(delta * speed, 1);
    if (code.current.has('KeyS')) move(-delta * speed, 1);
    if (code.current.has('KeyA')) move(-delta * speed);
    if (code.current.has('KeyD')) move(delta * speed);
  });

  return { zoom, move };
}
