import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';

import useStore from '../../../store';

import useKeyboardControls from '../hooks/useKeyboardControls';
import useMatchMedia from '../../../hooks/useMatchMedia';

const p = new THREE.Vector3();
const c = new THREE.Vector3();

const Controls = React.forwardRef((_, ref) => {
  const controlsRef = useRef<any>(null);
  const minDistance = useStore(state => state.explore.config.minDistance);
  const maxDistance = useStore(state => state.explore.config.maxDistance);
  const updateControls = useStore(state => state.explore.updateControls);
  const loading = useStore(state => state.explore.loading);
  const hasFilter = useStore(state => state.explore.hasFilter);

  const regress = useThree(state => state.performance.regress);
  const camera = useThree(state => state.camera);

  const { zoom } = useKeyboardControls();

  useEffect(() => {
    const controls = controlsRef.current;
    if (!controls) return;
    updateControls(controls);

    controls?.addEventListener('change', regress);
    return () => controls?.removeEventListener('change', regress);
  }, [controlsRef, updateControls]);

  useImperativeHandle(ref, () => ({
    resetCamera: () => {
      if (!controlsRef.current) return;
      controlsRef.current.target.set(0, 0, 0);
      camera.position.set(0, 0, 80);
    },
    zoom
  }));

  useFrame(() => {
    if (!controlsRef.current) return;
    controlsRef.current.update();

    if (hasFilter && loading) {
      controlsRef.current.target.lerp(c.set(0, 0, 0), 1);
      return camera.position.lerp(p.set(0, 0, maxDistance), 1);
    }

    // if (selectedPosition) {
    //   const [x, y] = selectedPosition;
    //   controlsRef.current.target.lerp(c.set(x, y, 0), 0.01);
    //   camera.position.lerp(p.set(x, y, 40), 0.01);
    // }
  });

  // useControlBounds();
  const isTouch = useMatchMedia('(pointer: coarse)');

  return (
    <OrbitControls
      makeDefault
      ref={controlsRef}
      enableRotate={false}
      mouseButtons={{
        LEFT: THREE.MOUSE.PAN,
        MIDDLE: THREE.MOUSE.PAN,
        RIGHT: THREE.MOUSE.PAN
      }}
      touches={{
        ONE: THREE.TOUCH.PAN,
        TWO: THREE.TOUCH.DOLLY_PAN
      }}
      enableDamping
      dampingFactor={isTouch ? 0.1 : 0.01}
      minDistance={minDistance}
      maxDistance={maxDistance}
    />
  );
});

Controls.displayName = 'Controls';

export default Controls;
