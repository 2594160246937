import { useRef } from 'react';
import { useRouter } from 'next/router';
import { useCursor } from '@react-three/drei';

import useStore from '../../../store';

import { GridItem } from './useGridPositions';

const useMousePointInteraction = (items: GridItem[]) => {
  const router = useRouter();
  const results = useStore(state => state.explore.results);
  const hasFilter = useStore(state => state.explore.hasFilter);
  const hovered = useStore(state => state.explore.hovered);
  const selectedPosition = useStore(state => state.explore.selectedPosition);
  const updateHovered = useStore(state => state.explore.updateHovered);
  const updateSelectedPosition = useStore(state => state.explore.updateSelectedPosition);

  const selected = useStore(state => state.general.selected);
  const updateSelected = useStore(state => state.general.updateSelected);

  useCursor(typeof hovered !== 'undefined');

  const mouseDownRef = useRef([0, 0]);

  const onPointerDown = (e: any) => {
    mouseDownRef.current[0] = e.clientX;
    mouseDownRef.current[1] = e.clientY;
  };

  const onClick = (event: any) => {
    const { instanceId, clientX, clientY } = event;
    const [x, y] = mouseDownRef.current;
    const downDistance = Math.sqrt(Math.pow(x - clientX, 2) + Math.pow(y - clientY, 2));

    // skip click if we dragged more than 5px distance
    if (downDistance > 5) {
      if (selected) {
        updateSelected(undefined);
        updateSelectedPosition(undefined);
      }

      if (hovered) {
        updateHovered(undefined);
      }

      return event.stopPropagation();
    }

    const current = items[instanceId];
    if (hasFilter && !results.includes(current.slug)) return;

    updateSelected(current.slug === selected ? undefined : current.slug);
    updateSelectedPosition(current.position === selectedPosition ? undefined : current.position);
    updateHovered(undefined);
  };

  const onPointerOver = (event: any) => {
    const { instanceId } = event;
    const current = items[instanceId];

    if (!hasFilter || (results.includes(current.slug) && !selected)) {
      updateHovered(hovered?.slug !== current.slug ? current : hovered, router.locale);
    }
  };

  const onPointerOut = () => updateHovered(undefined);

  return { onPointerDown, onPointerOver, onPointerOut, onClick, hovered };
};

export default useMousePointInteraction;
