import { Text, useGLTF, useTexture } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useMemo, useRef } from 'react';
import * as THREE from 'three';
import { GLTF } from 'three-stdlib';

import useStore from '../../../store';
import { BiographySimple } from '../../../types/api';

import useGridPositions, { GridItem } from '../hooks/useGridPositions';

import InstancedStone from './InstancedStone';
import InstancedRest from './InstancedRest';

type GLTFResult = GLTF & {
  nodes: { Stone_Root: THREE.Mesh };
  materials: {};
};

type StonesProps = { items: BiographySimple[] };

const mapInformationDensity = (items: GridItem[]) => {
  const data: { index: number; items: GridItem[] }[] = [
    { index: 0, items: [] },
    { index: 1, items: [] },
    { index: 2, items: [] },
    { index: 3, items: [] }
  ];

  items.forEach(item => {
    const i: GridItem = {
      ...item
      // informationDensity: getRandomItemFromArray([0, 0.25, 0.5, 0.75, 1]),
    };

    if (i.informationDensity === 0) return data[0].items.push(i);
    if (i.informationDensity === 0.25) return data[1].items.push(i);
    if (i.informationDensity === 0.5) return data[2].items.push(i);
    if (i.informationDensity === 0.75) return data[3].items.push(i);
    if (i.informationDensity === 1) return data[3].items.push(i);
  });

  return data.filter(i => i.items.length > 0);
};

const Stones = ({ items: initialItems }: StonesProps) => {
  const textRef = useRef<THREE.Group>(null);
  const results = useStore(state => state.explore.results);
  const hasFilter = useStore(state => state.explore.hasFilter);

  const { nodes } = useGLTF('/StoneRoot.gltf') as GLTFResult;

  const [color, alpha1, alpha2, alpha3, alpha4] = useTexture(
    [
      '/textures/Color.jpg',
      '/textures/Alpha01.jpg',
      '/textures/Alpha02.jpg',
      '/textures/Alpha03.jpg',
      '/textures/Alpha04.jpg'
    ],
    () => {
      color.flipY = false;
      alpha1.flipY = false;
      alpha2.flipY = false;
      alpha3.flipY = false;
      alpha4.flipY = false;
    }
  );

  useFrame(({ camera }) => {
    if (!textRef.current) return;
    textRef.current.visible = camera.position.z <= 200;
  });

  const alphaMaps = [alpha1, alpha2, alpha3, alpha4];

  const items = useGridPositions(initialItems);
  const mappedItems = useMemo(() => mapInformationDensity(items), [items]);

  const textBoxes = useMemo(
    () =>
      items.map(i => (
        <Text
          font="/fonts/jost.woff"
          characters="abcdefghijklmnopqrstuvwxyzöäüABCDEFGHIJKLMNOPQRSTXYZÖÄÜ-"
          key={i.position.join('-')}
          visible={hasFilter ? results.includes(i.slug) : true}
          color="#323232"
          position={[i.position[0], i.position[1] - 5, 1]}
          fontSize={1.1}
          maxWidth={12}
          lineHeight={1.2}
          letterSpacing={0.02}
          textAlign={'center'}
          anchorX="center"
          anchorY="middle"
        >
          {i.name}
        </Text>
      )),
    [items, results]
  );

  return (
    <>
      {mappedItems.map(i => (
        <InstancedStone
          key={i.index}
          items={i.items}
          geometry={nodes.Stone_Root.geometry}
          colorMap={color}
          alphaMap={alphaMaps[i.index]}
        />
      ))}

      <InstancedRest items={items} geometry={nodes.Stone_Root.geometry} alphaMap={alphaMaps[0]} />

      <group ref={textRef}>{textBoxes}</group>
    </>
  );
};

useGLTF.preload('/StoneRoot.gltf');

export default Stones;
