import { useHelper } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { getNumberByPercentage } from '../../../lib/helper';

import useStore from '../../../store';

const v = new THREE.Vector3();
const min = new THREE.Vector3();
const max = new THREE.Vector3();

const visibleHeight = (camera: THREE.PerspectiveCamera) => {
  // vertical fov in radians
  const vFOV = (camera.fov * Math.PI) / 180;
  // Math.abs to ensure the result is always positive
  return 2 * Math.tan(vFOV / 2) * Math.abs(camera.position.z);
};

const visibleWidth = (camera: THREE.PerspectiveCamera) => {
  const height = visibleHeight(camera);
  return height * camera.aspect;
};

const useControlBounds = (ref: React.RefObject<THREE.Mesh>) => {
  const camera = useThree(state => state.camera as THREE.PerspectiveCamera);
  const raycaster = useThree(state => state.raycaster);
  const prevX = useRef<number>(0);
  const prevY = useRef<number>(0);
  const prevZ = useRef<number>(200);

  const controls = useStore(state => state.explore.controls);
  const { width, height, gridHeight, gridWidth, maxDistance } = useStore(
    state => state.explore.config
  );

  const getIntersectionPoint = (x: -1 | 1 | 0, y: -1 | 1 | 0) => {
    if (!ref.current) return;
    raycaster.setFromCamera({ x, y } as THREE.Vector2, camera);
    const [intersection] = raycaster.intersectObjects([ref.current]) || [];
    return intersection ? intersection.point : undefined;
  };

  const getXPosition = () => {
    const left = getIntersectionPoint(-1, 0);
    const right = getIntersectionPoint(1, 0);

    const w = width / 2;
    if (left && left.x < w * -1) return prevX.current * -1;
    if (right && right.x > w) return prevX.current;
    prevX.current = camera.position.x;
    return width / 2;
  };

  const getYPosition = () => {
    const top = getIntersectionPoint(0, 1);
    const bottom = getIntersectionPoint(0, -1);

    const h = height / 2;
    if (bottom && bottom.y < (h + gridHeight) * -1) return prevY.current * -1;
    if (top && top.y > h) return prevY.current;
    prevY.current = camera.position.y;
    return height / 2;
  };

  const handleChange = (e: any) => {
    const c = e.target;

    const isZoomingOut = prevZ.current < camera.position.z;

    if (isZoomingOut) {
      const percentageZoomed = camera.position.z / maxDistance;

      const newX = getNumberByPercentage(prevX.current, 0, percentageZoomed);
      const newY = getNumberByPercentage(prevY.current, 0, percentageZoomed);
      camera.position.set(newX, newY, camera.position.z);
      controls.target.set(newX, newY, 0);

      prevZ.current = camera.position.z;

      return;
    }

    const x = getXPosition();
    const y = getYPosition();

    const minPan = min.set(-x, -y, 0);
    const maxPan = max.set(x, y, maxDistance);

    v.copy(c.target);
    c.target.clamp(minPan, maxPan);
    v.sub(c.target);
    camera.position.sub(v);

    prevZ.current = camera.position.z;
  };

  useEffect(() => {
    if (!controls) return;

    // Set camera position to center after resize
    camera.position.set(0, 0, camera.position.z);
    controls.target.set(0, 0, 0);

    controls.addEventListener('change', handleChange);
    return () => controls.removeEventListener('change', handleChange);
  }, [controls, width, height]);
};

export default useControlBounds;
