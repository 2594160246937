import { useFrame } from '@react-three/fiber';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useUpdateEffect } from 'react-use';
import * as THREE from 'three';

import useStore from '../../../store';

import { GridItem } from '../hooks/useGridPositions';
import useMousePointInteraction from '../hooks/useMousePointInteraction';

type InstancedRestProps = {
  items: GridItem[];
  geometry: THREE.BufferGeometry;
  alphaMap: THREE.Texture;
};

const boxObject = new THREE.Object3D();
const meshObject = new THREE.Object3D();
const tempColor = new THREE.Color();

const HIDDEN_COLOR = '#b2b7e0';
const RESULT_COLOR = '#293056';

const InstancedRest = ({ items, geometry, alphaMap }: InstancedRestProps) => {
  const hoverRef = useRef<THREE.InstancedMesh>(null);
  const boxRef = useRef<THREE.InstancedMesh>(null);
  const meshRef = useRef<THREE.InstancedMesh>(null);

  const { gridWidth, gridHeight } = useStore(state => state.explore.config);
  const results = useStore(state => state.explore.results);
  const hasFilter = useStore(state => state.explore.hasFilter);

  const numPoints = items.length;
  const colorAttrib = useRef<THREE.InstancedBufferAttribute>(null);
  const colorArray = useMemo(() => new Float32Array(numPoints * 3), [numPoints]);

  const interaction = useMousePointInteraction(items);
  const { onClick, onPointerOver, onPointerDown, onPointerOut } = interaction;

  const updateInitialInstances = useCallback(() => {
    if (!hoverRef.current || !boxRef.current || !colorAttrib.current || !meshRef.current) return;

    for (let i = 0; i < items.length; i += 1) {
      const { position, rotation, slug } = items[i];

      const isVisible = hasFilter ? results.includes(slug) : true;
      meshObject.position.set(position[0], position[1], position[2]);
      meshObject.rotation.set(rotation[0], rotation[1], rotation[2]);
      meshObject.scale.setScalar(isVisible ? 0 : 1);

      meshObject.updateMatrix();
      meshRef.current.setMatrixAt(i, meshObject.matrix);

      tempColor.set(RESULT_COLOR);
      tempColor.toArray(colorArray, i * 3);

      boxObject.position.set(position[0], position[1], 0);

      boxObject.updateMatrix();
      hoverRef.current.setMatrixAt(i, boxObject.matrix);
      boxRef.current.setMatrixAt(i, boxObject.matrix);
    }

    meshRef.current.instanceMatrix.needsUpdate = true;
    hoverRef.current.instanceMatrix.needsUpdate = true;

    colorAttrib.current.needsUpdate = true;
    boxRef.current.instanceMatrix.needsUpdate = true;
  }, [items, colorArray]);

  const updateInstances = useCallback(() => {
    if (!items || !meshRef.current || !boxRef.current || !colorAttrib.current) return;

    for (let i = 0; i < items.length; i += 1) {
      const { position, rotation, slug } = items[i];

      const isVisible = hasFilter ? results.includes(slug) : true;

      meshObject.position.set(position[0], position[1], position[2]);
      meshObject.rotation.set(rotation[0], rotation[1], rotation[2]);
      meshObject.scale.setScalar(isVisible ? 0 : 1);

      meshObject.updateMatrix();
      meshRef.current.setMatrixAt(i, meshObject.matrix);

      tempColor.set(isVisible ? RESULT_COLOR : HIDDEN_COLOR);
      tempColor.toArray(colorArray, i * 3);

      boxObject.position.set(position[0], position[1], 0);

      boxObject.updateMatrix();
      boxRef.current.setMatrixAt(i, boxObject.matrix);
    }

    colorAttrib.current.needsUpdate = true;
    boxRef.current.instanceMatrix.needsUpdate = true;

    meshRef.current.instanceMatrix.needsUpdate = true;
  }, [results, colorArray, items]);

  useEffect(() => {
    updateInitialInstances();
  }, [items, updateInitialInstances]);

  useUpdateEffect(() => {
    updateInstances();
  }, [results, updateInstances]);

  useFrame(({ camera }) => {
    if (!boxRef.current || !meshRef.current) return;
    const isBoxVisible = camera.position.z > 300;
    const isMeshVisible = camera.position.z <= 300;

    boxRef.current.castShadow = isBoxVisible;
    (boxRef.current.material as THREE.Material).opacity = isBoxVisible ? 1 : 0;
    (boxRef.current.material as THREE.Material).transparent = !isBoxVisible;

    meshRef.current.visible = isMeshVisible;
    meshRef.current.castShadow = isMeshVisible;
  });

  return (
    <>
      <instancedMesh ref={meshRef} args={[undefined, undefined, items.length]} geometry={geometry}>
        <meshBasicMaterial
          attach="material"
          alphaMap={alphaMap}
          alphaTest={0.1}
          transparent
          side={THREE.DoubleSide}
          color="#eee"
        />
      </instancedMesh>

      <instancedMesh
        ref={hoverRef}
        args={[undefined, undefined, items.length]}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
        onPointerDown={onPointerDown}
        onClick={onClick}
      >
        <planeBufferGeometry args={[gridWidth - 1, gridHeight - 1]} />
        <meshStandardMaterial attach="material" opacity={0} transparent />
      </instancedMesh>

      <instancedMesh ref={boxRef} args={[undefined, undefined, items.length]}>
        <planeBufferGeometry args={[gridWidth - 3, gridHeight - 7, 1]}>
          <instancedBufferAttribute
            ref={colorAttrib}
            attach="attributes-color"
            args={[colorArray, 3]}
          />
        </planeBufferGeometry>
        <meshStandardMaterial attach="material" vertexColors />
      </instancedMesh>
    </>
  );
};

export default React.memo(InstancedRest);
