import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import useFocusTrap from '../../../hooks/useFocusTrap';

import { color, mq, removeScrollbars, space } from '../../../stylesheets';
import Text from '../../../components/Text';
import Button from '../../../components/Button';

export const InfoLayerWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 900;
  position: absolute;
  top: 0;
`;

export const ShimLayer = styled(motion.button as any)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(248, 245, 242, 0.2);
  backdrop-filter: blur(5px);
`;

export const InfoLayerContentWrapper = styled(motion.div as any)`
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 700px;
  height: auto;
  margin: 0 auto;
  transform: translateY(-50%);
  padding: ${space('m')};
`;

export const InfoLayerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & > * + * {
    margin-top: ${space('m')};
  }
`;

export const OverlayHeader = styled.header`
  position: sticky;
  top: 0;
  background: ${color('neutral', 10)};
  height: ${space('xl')};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  z-index: 990;
  padding: 0 ${space('s')};

  & > div {
    flex-grow: 2;
    overflow: scroll;
    ${removeScrollbars}
  }

  ${mq('m')} {
    background: transparent;
    height: ${space('xxl')};
  }
`;

type InfoLayerProps = {
  onClose: () => void;
};

const InfoLayer = ({ onClose }: InfoLayerProps) => {
  const { t } = useTranslation('common');

  const { ref } = useFocusTrap<HTMLDivElement>({
    onKeyDown: e => {
      if (e.keyCode === 27) return onClose();
    }
  });

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      e.preventDefault();
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <InfoLayerWrapper>
      <ShimLayer
        key="infolayer-shim"
        onClick={onClose}
        type="button"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: 'easeInOut', duration: 0.4, delay: 0.3 }}
      />

      <InfoLayerContentWrapper
        key="infolayer-content"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: 'easeInOut', duration: 0.4, delay: 0.3 }}
        ref={ref}
      >
        <InfoLayerContent aria-live="assertive">
          <Text variant="headline-3" weight="normal">
            {t('infoLayer.copy')}
          </Text>

          <Button label={t('infoLayer.label')} onClick={onClose} variant="secondary" />
        </InfoLayerContent>
      </InfoLayerContentWrapper>
    </InfoLayerWrapper>
  );
};

export default InfoLayer;
