
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import type { GetServerSideProps, NextPage } from 'next';
import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';
import useStore from '../store';
import { shuffleArray } from '../lib/helper';
import fetcher from '../lib/fetcher';
import { BiographiesCompactResponse, FilterFacets, FilterFacetsResponse } from '../types/api';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Explore from '../features/Explore';
type HomeProps = {
    facets: FilterFacets;
    biographies: BiographiesCompactResponse;
};
const Home: NextPage<HomeProps> = ({ biographies, facets }) => {
    const { t } = useTranslation('common');
    const updateFacets = useStore(state => state.explore.updateFacets);
    const updateSelected = useStore(state => state.general.updateSelected);
    useEffect(() => {
        updateFacets(facets.birthDate);
        return () => updateSelected(undefined);
    }, []);
    return (<>
      <SEO canonical={`/`} title={t('seo.explore.title')} description={t('seo.explore.description')}/>

      <Layout hideFooter>
        <Explore items={biographies.results.map(i => ({
            slug: i[0],
            name: i[1],
            informationDensity: i[2]
        }))}/>
      </Layout>
    </>);
};
const getServerSideProps: GetServerSideProps = async ({ res, query, locale }) => {
    if (query.id) {
        return {
            redirect: {
                permanent: false,
                destination: `/biography/${query.id}`
            }
        };
    }
    const biographies = await fetcher<BiographiesCompactResponse>(`/${locale}/biographies`);
    const facets = await fetcher<FilterFacetsResponse>(`/${locale}/biographies/search/filters/`, {
        method: 'POST',
        body: JSON.stringify({ filter: {} })
    });
    res.setHeader('Cache-Control', 's-maxage=30, stale-while-revalidate=300');
    return {
        props: {
            facets: facets.data,
            biographies: {
                next: biographies.data.next,
                previous: biographies.data.previous,
                count: biographies.data.count,
                results: shuffleArray(biographies.data.results)
            }
        }
    };
};
export default Home;

    async function __Next_Translate__getServerSideProps__18d831d0a02__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__18d831d0a02__ as getServerSideProps }
  