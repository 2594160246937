// import { useHelper } from '@react-three/drei';
import { useSpring, animated } from '@react-spring/three';
import { useFrame, useThree } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import * as THREE from 'three';

import useStore from '../../../store';

const v = new THREE.Vector3();

export const SpotLight = () => {
  const ref = useRef<THREE.SpotLight>(null);
  const groupRef = useRef<THREE.Group>(null);
  const objectRef = useRef<THREE.Object3D>(null);
  const posRef = useRef<number[]>([0, 0, 0]);

  const camera = useThree(state => state.camera);
  const { width, height } = useStore(state => state.explore.config);
  const selectedPosition = useStore(state => state.explore.selectedPosition);
  const isSelected = typeof selectedPosition !== 'undefined';

  useEffect(() => {
    if (!posRef.current) return;
    if (selectedPosition) posRef.current = selectedPosition;
  }, [selectedPosition]);

  const { intensity } = useSpring({ intensity: isSelected ? 2.5 : 0 });

  useFrame(() => {
    if (!ref.current || !objectRef.current || !posRef.current) return;

    const pos = posRef.current;
    ref.current.position.lerp(v.set(pos[0], pos[1], 1000), 0.4);
    objectRef.current?.position.lerp(v.set(pos[0], pos[1], pos[2]), 0.4);
  });

  useEffect(() => {
    if (!ref.current || !objectRef.current) return;
    ref.current.target = objectRef.current;
  }, []);

  return (
    <group ref={groupRef}>
      <object3D ref={objectRef} />

      <animated.spotLight
        intensity={intensity}
        position={[0, 0, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        ref={ref}
        penumbra={1}
        angle={0.009}
        castShadow
        color="#fff"
        shadow-camera-near={camera.near}
        shadow-camera-far={camera.far}
        shadow-camera-left={-width / 2}
        shadow-camera-right={width / 2}
        shadow-camera-top={height / 2}
        shadow-camera-bottom={-height / 2}
      />
    </group>
  );
};

const Lightning = () => {
  const dir = useRef<THREE.DirectionalLight>(null);
  const camera = useThree(state => state.camera);

  const { width, height, maxDistance } = useStore(state => state.explore.config);
  const isSelected = useStore(state => typeof state.explore.selectedPosition !== 'undefined');

  const { intensity } = useSpring({ intensity: isSelected ? 1.4 : 1.4 });

  return (
    <>
      <ambientLight intensity={0.2} />

      {/* <SpotLight /> */}

      {/* @ts-ignore */}
      <animated.directionalLight
        intensity={intensity}
        args={[width, height]}
        position={[-50, 50, maxDistance]}
        ref={dir}
        color="#fff"
        castShadow
        shadow-camera-near={camera.near}
        shadow-camera-far={camera.far}
        shadow-camera-left={-width}
        shadow-camera-right={width}
        shadow-camera-top={height}
        shadow-camera-bottom={-height}
      />
    </>
  );
};

export default Lightning;
