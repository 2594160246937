import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';

import Button from '../../../../components/Button';

import { color, mq, space } from '../../../../stylesheets';

const StyledZoomControls = styled.div`
  position: fixed;
  bottom: calc(${space('xxl')} + ${space('s')});
  right: ${space('s')};
  margin-bottom: ${space('m')};
  padding: 0;
  z-index: 800;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column-reverse;
  max-width: 700px;
  background: ${color('neutral', 10)};

  ${mq('l')} {
    bottom: ${space('l')};
    right: ${space('l')};
    margin-bottom: 0;
  }
`;

type ZoomControlsProps = {
  onZoom: (distance: number) => void;
  onReset: () => void;
};

const ZoomControls = ({ onZoom, onReset }: ZoomControlsProps) => {
  const { t } = useTranslation('common');

  return (
    <StyledZoomControls>
      <Button
        onClick={() => onZoom(40)}
        iconPosition="only"
        label={t('labels.zoomOut')}
        variant="tertiary"
        icon="ZoomOut"
      />
      <Button
        onClick={() => onZoom(-40)}
        iconPosition="only"
        label={t('labels.zoomIn')}
        variant="tertiary"
        icon="ZoomIn"
      />
    </StyledZoomControls>
  );
};

export default ZoomControls;
