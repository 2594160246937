import React, { useRef } from 'react';
import * as THREE from 'three';

import useStore from '../../../store';
import useControlBounds from '../hooks/useControlBounds';

const plane = new THREE.PlaneGeometry();
const material = new THREE.MeshStandardMaterial({ color: '#fff' });

const Plane = () => {
  const { width, height } = useStore(state => state.explore.config);
  const ref = useRef<THREE.Group>(null);
  const planeRef = useRef<THREE.Mesh>(null);

  useControlBounds(planeRef);

  return (
    <group position={[0, 0, 0]} ref={ref}>
      <mesh
        ref={planeRef}
        receiveShadow
        scale={[width * 2, height * 2, 1]}
        rotation={[0, 0, 0]}
        geometry={plane}
        material={material}
      />
    </group>
  );
};

Plane.displayName = 'Plane';

export default Plane;
