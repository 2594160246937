import React, { useCallback, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';
import debounce from 'lodash.debounce';

import { BiographySimple } from '../../types/api';
import useStore from '../../store';

import Spinner from '../../components/Spinner';
import Overlay from '../../components/Overlay';

import ZoomControls from './components/ZoomControls';
import Canvas from './components/Canvas';
import Filter from './components/Filter';
import InfoLayer from './components/InfoLayer';
import ShareButton from '../../components/ShareButton';

// import HoverBadge from './components/HoverBadge';
import Breadcrumb from '../Biography/components/Breadcrumb';
import Biography from '../Biography';

import { hideVisually, space } from '../../stylesheets';

type ExploreProps = {
  items: BiographySimple[];
};

const ExploreWrapper = styled.div`
  position: relative;
  width: 100vw;
  overflow: hidden;
`;

const ScreenReaderText = styled.div`
  ${hideVisually}
`;

const Loader = styled.div`
  position: absolute;
  top: 0;
  z-index: 9;
  padding: ${space(['xs', 'l', 'm'])};
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(248, 245, 242, 0.4);
  backdrop-filter: blur(3px);
`;

const Explore = ({ items }: ExploreProps) => {
  const ref = useRef<any>(null);
  const { t } = useTranslation('common');

  const gridWidth = useStore(state => state.explore.config.gridWidth);
  const updateConfig = useStore(state => state.explore.updateConfig);
  const loading = useStore(state => state.explore.loading);
  const resetFilter = useStore(state => state.explore.resetFilter);
  const updateSelectedPosition = useStore(state => state.explore.updateSelectedPosition);
  const hovered = useStore(state => state.explore.hovered);
  const toggleInfoLayer = useStore(state => state.explore.toggleInfoLayer);
  const isInfoLayerVisible = useStore(state => state.explore.isInfoLayerVisible);
  const isFilterVisible = useStore(state => state.explore.isFilterVisible);

  const biography = useStore(state => state.general.biography);
  const glossary = useStore(state => state.general.glossary);
  const selected = useStore(state => state.general.selected);
  const loadingBiography = useStore(state => state.general.loadingBiography);
  const updateSelected = useStore(state => state.general.updateSelected);
  const isOverlayVisible = typeof selected !== 'undefined';

  const updateGridSize = useCallback(() => {
    const { innerWidth, innerHeight } = window;
    const headerHeight = innerWidth < 768 ? 48 : 60;
    const canvasHeight = innerHeight - headerHeight;

    const ratio = canvasHeight / innerWidth;

    const elementsPerRow = Math.ceil(Math.sqrt(items.length / ratio));
    const width = elementsPerRow * gridWidth;
    const height = elementsPerRow * gridWidth * ratio;
    const distance = height >= width ? height : width / 2.1;

    updateConfig({
      width,
      height,
      ratio,
      elementsPerRow,
      maxDistance: distance,
      far: width * 5
    });
  }, [items, updateConfig, gridWidth]);

  const handleResetCamera = () => {
    if (!ref.current) return;
    ref.current.resetCamera();
    resetFilter();
  };

  const handleZoom = (z: number) => {
    if (!ref.current) return;
    ref.current.zoom(z);
  };

  const handleRouteChange = (slug: string) => updateSelected(slug);

  useEffect(() => {
    updateGridSize();
    window.addEventListener('resize', debounce(updateGridSize, 200));
    return () => window.removeEventListener('resize', debounce(updateGridSize, 200));
  }, [updateGridSize]);

  useEffect(() => {
    return () => {
      updateSelectedPosition(undefined);
      updateSelected(undefined);
    };
  }, []);

  return (
    <>
      <ExploreWrapper>
        {loading && (
          <Loader>
            <LoaderBackground />
            <Spinner />
          </Loader>
        )}

        <Canvas ref={ref} items={items} />

        <AnimatePresence>
          {isInfoLayerVisible && <InfoLayer onClose={() => toggleInfoLayer(false)} />}
        </AnimatePresence>

        <div
          style={{ opacity: isFilterVisible ? 1 : 0, transition: 'opacity 0.4s 0.3s ease-in-out' }}
        >
          <ZoomControls onZoom={handleZoom} onReset={handleResetCamera} />
          <Filter />
        </div>

        <AnimatePresence>
          {isOverlayVisible && (
            <Overlay
              id={biography?.name}
              header={
                biography ? (
                  <Breadcrumb
                    hasRelatives={(biography.relatives || []).length > 0}
                    hasAnnotations={(biography.annotations || []).length > 0}
                  />
                ) : undefined
              }
              buttons={<ShareButton />}
              onClose={() => {
                updateSelected(undefined);
                updateSelectedPosition(undefined);
              }}
              loading={loadingBiography || !biography}
              ariaLabelledBy={
                biography ? t('accessibility.biography.label', { name: biography.name }) : undefined
              }
            >
              {biography && !loadingBiography && (
                <Biography
                  {...biography}
                  glossary={glossary}
                  onRouteChange={handleRouteChange}
                  key={selected}
                />
              )}
            </Overlay>
          )}

          {/* {hovered && (
            <HoverBadge
              name={hovered.name}
              loading={loadingBiography || !biography}
              birthDate={biography?.birthDate}
              birthDatePartial={biography?.birthDatePartial}
              deathDate={biography?.deathDate}
              deathDatePartial={biography?.deathDatePartial}
            />
          )} */}
        </AnimatePresence>
      </ExploreWrapper>

      {hovered && (
        <ScreenReaderText aria-live="assertive">
          {t('accessibility.biography.hover', { name: hovered.name })}
        </ScreenReaderText>
      )}
    </>
  );
};

export default Explore;
