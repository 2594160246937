import React from 'react';
import { EffectComposer, Vignette } from '@react-three/postprocessing';

export default function Effects() {
  return (
    <EffectComposer multisampling={0} disableNormalPass={true}>
      {/* <Noise opacity={0.1} /> */}
      <Vignette eskil={false} offset={0.01} darkness={0.2} />
      {/* <BadTV /> */}
    </EffectComposer>
  );
}
