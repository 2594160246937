import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';

import useStore from '../../../store';

import { GridItem } from '../hooks/useGridPositions';

type InstancedStoneProps = {
  items: GridItem[];
  geometry: THREE.BufferGeometry;
  colorMap: THREE.Texture;
  alphaMap: THREE.Texture;
};

const object = new THREE.Object3D();
const c = new THREE.Vector3();

type InstancedGridItem = GridItem & { originalPosition?: number[] };

const InstancedStone = ({ items, geometry, colorMap, alphaMap }: InstancedStoneProps) => {
  const meshRef = useRef<THREE.InstancedMesh>(null);

  const results = useStore(state => state.explore.results);
  const hasFilter = useStore(state => state.explore.hasFilter);
  const hovered = useStore(state => state.explore.hovered?.slug);
  const selected = useStore(state => state.general.selected);
  const camera = useThree(state => state.camera);

  const itemsRef = useRef<InstancedGridItem[] | null>(items);
  useEffect(() => void (itemsRef.current = items), [items]);

  const prevIndexes = useRef<number[]>([]);
  const activeIndex = useRef<number | null>(null);

  useEffect(() => {
    const prevIndex = activeIndex.current;
    const index = items.findIndex(i => i.slug === (selected || hovered));

    activeIndex.current = index > -1 ? index : null;

    /**
     * Remove from prev indexes if included to ensure
     * not both indexes animating against each other
     */
    if (activeIndex.current && prevIndexes.current.includes(activeIndex.current)) {
      prevIndexes.current = prevIndexes.current.filter(i => i !== activeIndex.current);
    }

    return () => {
      if (index > -1) {
        prevIndexes.current = [...prevIndexes.current, index];
      } else if (prevIndex) {
        prevIndexes.current = [...prevIndexes.current, prevIndex];
      }
    };
  }, [hovered, selected]);

  const updateInstance = (i: number, position: number[], scale: number, rotation?: number[]) => {
    if (!meshRef.current || !itemsRef.current) return;
    const item = itemsRef.current[i];
    item.scale = THREE.MathUtils.lerp(item.scale, scale, 0.05);

    item.position = [
      THREE.MathUtils.lerp(item.position[0], position[0], 0.05),
      THREE.MathUtils.lerp(item.position[1], position[1], 0.05),
      THREE.MathUtils.lerp(item.position[2], position[2], 0.05)
    ];

    if (rotation) {
      item.rotation = [rotation[0], rotation[1], rotation[2]];
    }

    object.position.set(item.position[0], item.position[1], item.position[2]);
    object.rotation.set(item.rotation[0], item.rotation[1], item.rotation[2]);
    object.scale.setScalar(item.scale);

    object.updateMatrix();
    meshRef.current.setMatrixAt(i, object.matrix);
  };

  const updateActiveIndex = useCallback(() => {
    if (!itemsRef.current || !meshRef.current || !activeIndex.current) return;

    const i = activeIndex.current;
    const item = itemsRef.current[i];
    const z = selected ? 10 : 6;
    const scale = selected ? 1.4 : 1.2;

    updateInstance(i, [item.position[0], item.position[1], z], scale, [
      item.rotation[0] + 0.001,
      item.rotation[1] + 0.001,
      item.rotation[2] + 0.001
    ]);

    meshRef.current.instanceMatrix.needsUpdate = true;
  }, [selected]);

  const updatePrevInstances = useCallback(() => {
    if (!itemsRef.current || !meshRef.current || !prevIndexes.current) return;

    for (let j = 0; j < prevIndexes.current.length; j++) {
      const i = prevIndexes.current[j];
      const item = itemsRef.current[i];

      const pos = item.originalPosition || item.position;
      updateInstance(i, [pos[0], pos[1], 4.5], 1);

      if (item.scale < 1.001) {
        prevIndexes.current = prevIndexes.current.filter(a => a !== i);
      }
    }

    meshRef.current.instanceMatrix.needsUpdate = true;
  }, []);

  const updateInstances = useCallback(() => {
    if (!itemsRef.current || !meshRef.current) return;

    for (let i = 0; i < itemsRef.current.length; i++) {
      const { position, rotation, slug } = itemsRef.current[i];
      itemsRef.current[i].originalPosition = position;

      const isVisible = hasFilter ? results.includes(slug) : true;

      object.position.set(position[0], position[1], position[2]);
      object.rotation.set(rotation[0], rotation[1], rotation[2]);
      object.scale.setScalar(isVisible ? 1 : 0);

      object.updateMatrix();
      meshRef.current.setMatrixAt(i, object.matrix);
    }

    meshRef.current.instanceMatrix.needsUpdate = true;
  }, [results, selected]);

  useFrame(({ camera }) => {
    if (!meshRef.current || !itemsRef.current) return;
    const isMeshVisible = camera.position.z <= 300;

    meshRef.current.visible = isMeshVisible;
    meshRef.current.castShadow = isMeshVisible;

    if (isMeshVisible) {
      updateActiveIndex();
      updatePrevInstances();
    }
  });

  useEffect(() => {
    updateInstances();
  }, [results, items, updateInstances]);

  const memoizedComponent = useMemo(
    () => (
      <instancedMesh
        ref={meshRef}
        args={[undefined, undefined, items.length]}
        geometry={geometry}
        castShadow
        receiveShadow
      >
        <meshStandardMaterial
          roughness={1}
          metalness={0}
          attach="material"
          map={colorMap}
          alphaMap={alphaMap}
          alphaTest={0.3}
          transparent
          side={THREE.DoubleSide}
        />
      </instancedMesh>
    ),
    []
  );

  return memoizedComponent;
};

export default React.memo(InstancedStone);
