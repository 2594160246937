import { useMemo } from 'react';

import { getRandomRotation } from '../../../lib/helper';
import { BiographySimple } from '../../../types/api';
import useStore from '../../../store';

export type GridItem = BiographySimple & { position: number[]; rotation: number[]; scale: number };

const useGridPositions = (items: BiographySimple[] = []) => {
  const { width, height, gridHeight, gridWidth, elementsPerRow } = useStore(
    state => state.explore.config
  );

  const getBase = () => {
    return { baseX: -(width / 2) + gridWidth / 2, baseY: height / 2 - gridHeight / 2 };
  };

  const { baseX, baseY } = getBase();

  let currentX = baseX;
  let currentY = baseY;

  return useMemo(() => items.map((item, i) => {
    if (i !== 0) {
      if (i % elementsPerRow === 0) {
        currentY -= gridHeight;
        currentX = baseX;
      } else {
        currentX += gridWidth;
      }
    }

    return {
      ...item,
      position: [currentX, currentY, 4.5],
      scale: 1,
      rotation: [
        getRandomRotation(-Math.PI, Math.PI),
        getRandomRotation(-Math.PI, Math.PI),
        getRandomRotation(-Math.PI, Math.PI)
      ]
    } as GridItem;
  }), [baseX, elementsPerRow, gridHeight, gridWidth]);
};

export default useGridPositions;
