import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useRef } from 'react';
import styled from 'styled-components';
import Link from 'next/link';

import { FilterObject } from '../../../../types/api';
import { ExploreFilterKey } from '../../../../store/types';
import useStore from '../../../../store';

import RangeSlider from '../../../../components/RangeSlider';
import Icon from '../../../../components/Icon';
import Text from '../../../../components/Text';

import { color, mq, removeScrollbars, space } from '../../../../stylesheets';

const StyledFilter = styled.div`
  position: fixed;
  z-index: 10;
  bottom: ${space('m')};
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  ${mq('m')} {
    width: auto;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: scroll;
  width: 100%;
  padding: ${space(['s', 'm'])};

  ${removeScrollbars}

  & > * + * {
    margin-left: ${space('xs')};
  }

  & > div:first-of-type {
    display: flex;
    align-items: center;

    & > * + * {
      margin-left: ${space('xs')};
    }
  }

  & > div:last-of-type {
    flex-shrink: 0;
    min-width: 400px;
  }
`;

const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 ${space('m')};

  & > div {
    padding: 0;
    display: flex;
    margin: 0;
    align-items: center;
    background: ${color('neutral', 10)};
    padding: ${space(['xxs', 'xs'])};

    svg {
      margin-left: ${space('xxs')};
      margin-right: ${space('xxs')};
    }
  }

  a:hover,
  a:focus {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
  }
`;

const Tag = styled.button<{ $active?: boolean }>`
  border: 2px solid ${color('primary')};
  padding: 0 ${space('s')};
  display: flex;
  align-items: center;
  height: 50px;
  background: ${props => (props.$active ? color('primary') : color('neutral', 10))};
  color: ${props => (props.$active ? color('neutral', 10) : color('primary'))};
  transition: all 0.2s ease-in-out;
  box-shadow: 0 0 0px 0px ${color('primary')};

  &:hover {
    box-shadow: 0 0 5px 2px ${color('primary', 20)};
  }

  &[disabled] {
    cursor: not-allowed;
  }

  svg {
    margin-right: -4px;
  }

  p {
    flex-shrink: 0;
    white-space: nowrap;
  }
`;

const Filter = () => {
  const { t } = useTranslation('common');
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const router = useRouter();

  const filter = useStore(state => state.explore.filter);
  const facets = useStore(state => state.explore.facets);
  const loading = useStore(state => state.explore.loading);
  const updateFilter = useStore(state => state.explore.updateFilter);
  const fetchResults = useStore(state => state.explore.fetchResults);

  const handleSearch = async () => {
    try {
      await fetchResults(router.locale);
    } catch (error) {
      console.log('Error while searching');
    }
  };

  const handleFilterUpdate = <T extends ExploreFilterKey, R extends FilterObject[T]>(
    key: T,
    value: R
  ) => {
    if (timeout.current) clearTimeout(timeout.current);
    updateFilter(key, value);
    timeout.current = setTimeout(handleSearch, 500);
  };

  return (
    <StyledFilter>
      <FilterHeader>
        <div>
          <Text as="span" variant="form-label">
            {t('labels.filterBiographies')}
          </Text>
          <Icon icon="Right" size="supertiny" />
          <Link href="/search" passHref legacyBehavior>
            <Text as="a" variant="form-label">
              {t('menu.search')}
            </Text>
          </Link>
        </div>
      </FilterHeader>

      <FilterWrapper>
        <div>
          <Tag
            $active={filter.hasBirthday}
            type="button"
            aria-label={t('accessibility.filterBy', { title: t('tags.onThisDay') })}
            disabled={loading}
            onClick={() => handleFilterUpdate('hasBirthday', filter.hasBirthday ? undefined : true)}
          >
            <Text variant="button" transform="uppercase">
              {t('tags.onThisDay')}
            </Text>

            {filter.hasBirthday && <Icon icon="Reset" />}
          </Tag>

          <Tag
            $active={filter.child}
            type="button"
            aria-label={t('accessibility.filterBy', { title: t('tags.children') })}
            disabled={loading}
            onClick={() => handleFilterUpdate('child', filter.child ? undefined : true)}
          >
            <Text variant="button" transform="uppercase">
              {t('tags.children')}
            </Text>

            {filter.child && <Icon icon="Reset" />}
          </Tag>

          <Tag
            $active={filter.famousPerson}
            type="button"
            disabled={loading}
            aria-label={t('accessibility.filterBy', { title: t('tags.famousPersons') })}
            onClick={() =>
              handleFilterUpdate('famousPerson', filter.famousPerson ? undefined : true)
            }
          >
            <Text variant="button" transform="uppercase">
              {t('tags.famousPersons')}
            </Text>

            {filter.famousPerson && <Icon icon="Reset" />}
          </Tag>
        </div>

        <RangeSlider
          alignment="horizontal"
          id="birthDate"
          label={t('form.birthDate.label')}
          disabled={loading}
          value={filter.birthDate ? [filter.birthDate.from, filter.birthDate.to] : undefined}
          min={facets.birthDate.min}
          max={facets.birthDate.max}
          onChange={val =>
            handleFilterUpdate(
              'birthDate',
              val[0] > facets.birthDate.min || val[1] < facets.birthDate.max
                ? { from: val[0], to: val[1] }
                : undefined
            )
          }
        />
      </FilterWrapper>
    </StyledFilter>
  );
};

export default Filter;
