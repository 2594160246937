import React, { Suspense, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Canvas as ThreeCanvas } from '@react-three/fiber';
import { AdaptiveDpr, Html, PerformanceMonitor, Stats } from '@react-three/drei';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { BiographySimple } from '../../../types/api';
import useStore from '../../../store';

import Spinner from '../../../components/Spinner';

import Controls from './Controls';
import Lightning from './Lightning';
import Plane from './Plane';
import Effects from './Effects';
import Stones from './Stones';

import { color, mq, space } from '../../../stylesheets';

type CanvasProps = {
  items: BiographySimple[];
};

const StyledCanvas = styled.div`
  position: relative;

  @media print {
    display: none;
  }
`;

const CanvasWrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - ${space('xl')}) !important;
  background: ${color('base')};

  ${mq('m')} {
    height: calc(100vh - ${space('xxl')}) !important;
  }
`;

const Loading = styled.div`
  width: 100vw;
  height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #f8f5f2;
  color: #293056;
`;

function Loader() {
  const toggleInfoLayer = useStore(state => state.explore.toggleInfoLayer);

  useEffect(() => {
    return () => toggleInfoLayer(true);
  }, []);

  return (
    <Html center zIndexRange={[100, 0]}>
      <Loading>
        <Spinner />
      </Loading>
    </Html>
  );
}

const Canvas = React.forwardRef(({ items }: CanvasProps, ref: any) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const controlsRef = useRef<any>(null);
  const router = useRouter();

  const [dpr, setDpr] = useState(typeof window !== 'undefined' ? window.devicePixelRatio : 1.5);

  const { near, far } = useStore(state => state.explore.config);
  const toggleInfoLayer = useStore(state => state.explore.toggleInfoLayer);
  const isInfoLayerVisible = useStore(state => state.explore.isInfoLayerVisible);
  const reset = useStore(state => state.explore.reset);

  const selected = useStore(state => state.general.selected);
  const updateSelected = useStore(state => state.general.updateSelected);

  useEffect(() => {
    return () => reset();
  }, []);

  useEffect(() => {
    if (selected) {
      router.replace({ pathname: '/', query: { id: selected } }, undefined, { shallow: true });
    } else {
      router.push('/', undefined, { shallow: true });
    }
  }, [selected]);

  useEffect(() => {
    const slug = router.query.id;

    if (slug && typeof slug === 'string') {
      const selectedItem = items.find(a => a.slug === slug);
      updateSelected(selectedItem?.slug, router.locale);
      toggleInfoLayer(false);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    resetCamera: () => {
      if (!controlsRef.current) return;
      return controlsRef.current.resetCamera();
    },
    zoom: (z: number) => {
      if (!controlsRef.current) return;
      return controlsRef.current.zoom(z);
    }
  }));

  return (
    <StyledCanvas>
      <CanvasWrapper>
        <ThreeCanvas
          ref={canvasRef}
          shadows
          frameloop={isInfoLayerVisible ? 'demand' : 'always'}
          gl={{
            powerPreference: 'high-performance',
            antialias: false,
            stencil: false,
            alpha: false
          }}
          dpr={dpr}
          performance={{ min: 0.7, debounce: 100 }}
          camera={{
            position: [0, 0, 200],
            fov: 50,
            rotation: [0, 0, 0],
            near,
            far
          }}
        >
          <color attach="background" args={['#fff']} />
          {process.env.NODE_ENV === 'development' && <Stats />}

          <AdaptiveDpr pixelated />
          <PerformanceMonitor onIncline={() => setDpr(2)} onDecline={stats => setDpr(1.5)} />

          <Lightning />
          <Controls ref={controlsRef} />

          <Suspense fallback={<Loader />}>
            <Stones items={items} />
            <Plane />
          </Suspense>

          <Effects />
        </ThreeCanvas>
      </CanvasWrapper>
    </StyledCanvas>
  );
});

Canvas.displayName = 'Canvas';

export default Canvas;
